
// class component
import ViewModel from '@/models/ViewModel'
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import DataForm from '@/components/DataForm/DataForm.vue'
import CreditMemo from '@/models/CreditMemo'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormInput from '@/components/FormInput/FormInput.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import InvoicePicker from '@/components/InvoicePicker/InvoicePicker.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import DataTable from '@/components/DataTable/index.vue'
import CreditMemoItem from '@/models/CreditMemoItem'
import Widget from '@/components/Widget/Widget.vue'
import { integerMask, currencyMask } from '@/models/interface/Masks'
import IconAction from '@/components/IconAction/IconAction.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'

import itemsTableFields from './items-table-fields'
import formData from './form'

@Component({
  components: {
    DataForm,
    FormWizard,
    TabContent,
    FormInput,
    CompanyPicker,
    SelectPicker,
    InvoicePicker,
    FooterNav,
    DataTable,
    Widget,
    IconAction,
    DatePicker,
  },
})
export default class CreditMemoEdit extends ViewModel {
  @Ref() readonly formWizard!: any

  @Ref() readonly table!: any

  @Ref() readonly client_picker!: any

  public credit_memo = new CreditMemo()

  public formFields: any = formData

  public busy = true

  public step: number = 1

  public items_fields: any = itemsTableFields

  public local_client: any = null

  public local_invoice: any = null

  public type_list: any = [
    { value: 'credit', name: 'Credit' },
    { value: 'debit', name: 'Debit' },
  ]

  @Watch('step')
  public onStepChange(val: any) {
    if (!this.credit_memo.items.length && val === 2) {
      this.addEmptyItem()
    }
  }

  public get header_items() {
    return [
      {
        label: 'Memo Name',
        value: this.credit_memo.name,
      },
      {
        label: 'Type',
        value: this.credit_memo.type,
      },
      {
        label: 'Client',
        value: this.local_client?.name || this.credit_memo.client?.name,
      },
      {
        label: 'Invoice',
        value: this.local_invoice?.name || this.credit_memo.invoice?.name,
      },
    ]
  }

  public get show_fields() {
    return this.items_fields.filter((field: any) => field.show)
  }

  public get _isEditing() {
    return !!this.$route.params.id
  }

  private get masks() {
    return {
      integerMask,
      currencyMask,
    }
  }

  public created() {
    if (this._isEditing) {
      this.busy = true
      CreditMemo.find(this.$route.params.id).then(result => {
        this.credit_memo = result
        setTimeout(() => {
          this.loading = false
          this.busy = false
        }, 700)
        setTimeout(() => {
          this.formWizard.activateAll()
        }, 1000)
        return result
      })
    } else {
      this.busy = false
    }
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }

  public validateStep() {
    return true
  }

  public addEmptyItem() {
    this.credit_memo.items.push(new CreditMemoItem())
    setTimeout(() => {
      this.table.refresh()
    }, 300)
  }

  public removeItem(index: number) {
    this.credit_memo.items.splice(index, 1)
    setTimeout(() => {
      this.table.refresh()
    }, 300)
  }

  public copyItem(index: number) {
    const item: any = { ...this.credit_memo.items[index] }
    this.credit_memo.items.push(item)
    setTimeout(() => {
      this.table.refresh()
    }, 300)
  }

  public async onSubmit() {
    this.loading = true
    this.credit_memo
      .save()
      .then((res: any) => {
        this.$router.push({ name: 'credit-memos' })
      })
      .catch((err: any) => {
        this.busy = false
      })
  }
}
