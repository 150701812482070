export default {
  name: 'Credit Memo',
  submitText: 'Save & Close',
  actions: [
    {
      name: 'Save & Download Proposal XML',
      icon: 'download',
      action: 'saveDownload',
      visible: false,
    },
  ],
  sections: [
    {
      name: 'Memo Information',
      icon: 'sticky-note',
      actions: [],
      fields: [
        {
          name: 'Plan Type',
          type: 'picker',
          target: 'formType',
          rules: 'required',
        },
      ],
    },
    {
      name: 'Memo Information',
      icon: 'sticky-note',
      actions: [],
      fields: [
        {
          name: 'Plan Type',
          type: 'picker',
          target: 'formType',
          rules: 'required',
        },
      ],
    },
  ],
}
