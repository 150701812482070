export default [
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },

  {
    key: 'amount',
    label: 'Amount',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'product',
    label: 'Product',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'action',
    label: '',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },

]
