import CreditMemoItem from './CreditMemoItem'
import Model from './interface/Model'

export default class CreditMemo extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'credit_memo' as string | null,
      plural: 'credit_memos' as string | null,
    },
  }

  public id: null | string = null

  public type: string = 'credit'

  public name: null | string = null

  public number: null | string = null

  public total_amount: number = 0

  public client_id: null | string = null

  public invoice_id: null | string = null

  public external_id: null | string = null

  public external_revision: null | string = null

  public items: CreditMemoItem[] = []

  public created_at: any = null

  public client: any = null

  public invoice: any = null

  public get itemsLength(): number {
    return this.items.length
  }

  public get items_total_amount(): number {
    return this.items.reduce((total, item) => total + Number(item.amount), 0)
  }

  public get apiData(): any {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      items: this.items,
      total_amount: this.items_total_amount,
      client_id: this.client_id,
      invoice_id: this.invoice_id,
      external_id: this.external_id,
      external_revision: this.external_revision,
      created_at: this.created_at,
    }
  }

  public toObject(source: any) {
    // @ts-ignore
    let instance = this.clone()

    Object.assign(instance, source)

    if (source.items) {
      instance.items = CreditMemoItem.toObjectList(source.items)
    }

    return instance
  }
}
