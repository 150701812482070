import Model from './interface/Model'

export default class CreditMemoItem extends Model {
    public id: null | string = null

    public name: null | string = null

    public number: null | string = null

    public amount: number = 0

    public product: string = 'SSL'

    public credit_memo_id: null | string = null

    public external_id: null | string = null

    public created_at: any = null

    public get apiData(): any {
      return {
        id: this.id,
        name: this.name,
        amount: this.amount,
        product: this.product,
        credit_memo_id: this.credit_memo_id,
        external_id: this.external_id,
        created_at: this.created_at,
      }
    }
}
