
import {
  Vue, Component, Prop, Ref, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import WebMessage from '@/models/WebMessage'
import FormBuilder from './FormBuilder.vue'

@Component({
  // @ts-ignore
  components: {
    Widget,
    FormBuilder,
    IconAction,
    FormWizard,
    TabContent,
  },
})
export default class DataForm extends Vue {
  @Ref() readonly formWizard: any

  @Ref() readonly observer: any

  @Prop()
  private value: any

  @Prop({ default: false })
  private noHeader!: boolean

  @Prop({ default: false })
  private noFooter!: boolean

  @Prop({ default: false })
  private busy!: boolean

  @Prop({ default: false })
  private loading!: boolean

  @Prop({ default: false })
  private activateAll!: boolean

  @Prop({ default: true })
  private container!: any

  private local_container: boolean = true

  @Watch('container')
  private onContainerChange(value: any) {
    this.local_container = value
  }

  private localBusy: boolean = false

  private localLoading: boolean = false

  private get localValue() {
    return this.value
  }

  private set localValue(value: any) {
    this.$emit('input', value)
  }

  @Prop({ required: true })
  private formData: any

  private active_section: number = 0

  @Watch('active_section')
  private onActiveSectionChange(val: any) {
    if (val !== null) this.$emit('changedTab', val)
  }

  private get visibleSection() {
    return this.visibleSections[this.active_section]
  }

  public get visibleSections() {
    return this.formData.sections.filter(
      (section: any) => section.visible === undefined || section.visible(this.localValue),
    )
  }

  public get visibleSectionActions() {
    if (this.visibleSection.actions) {
      return this.visibleSection.actions.filter(
        (action: any) => action.visible === undefined || action.visible(this.localValue),
      )
    }

    return []
  }

  public get visibleActions() {
    return this.formData.actions.filter(
      (action: any) => action.visible === undefined || action.visible(this.localValue),
    )
  }

  public onSubmit() {
    if (!this.localBusy && !this.localLoading) {
      this.observer.reset()
      this.observer.validate().then((valid: boolean) => {
        if (valid && typeof this.visibleSection.validation === 'function') {
          this.visibleSection
            .validation(this.localValue)
            .then(() => {
              this.$emit('submit', this.localValue)
            })
            .catch((error: Error) => {
              WebMessage.error(error.message)
              WebMessage.error(
                'We were not able to save the record, please fix the errors before saving.',
              )
            })
        } else if (valid) {
          this.$emit('submit', this.localValue)
        } else {
          WebMessage.error(
            'We were not able to save the record, please fix the errors before saving.',
          )
        }
      })
    }
  }

  public cancel() {
    if (!this.localBusy && !this.localLoading) {
      this.$router.back()
    }
  }

  public error(message: string) {
    WebMessage.error(message)
  }

  public actionIcon(action: any): string {
    if (typeof action.icon === 'string') return action.icon
    return action.icon(this.localValue)
  }

  public validateTriggerAction(action: any) {
    if (!this.localBusy && !this.localLoading) {
      if (typeof this.visibleSection.validation === 'function') {
        this.visibleSection
          .validation(this.localValue)
          .then(() => {
            this.triggerAction(action)
          })
          .catch((error: Error) => {
            WebMessage.error(error.message)
          })
      } else {
        this.triggerAction(action)
      }
    }
  }

  public triggerAction(action: any, helper: any = null) {
    if (!this.localBusy && !this.localLoading) {
      if (helper && typeof helper === 'function') {
        helper()
      }
      if (typeof action === 'string') {
        if (action === 'save') {
          this.observer.reset()
          this.observer.validate().then((valid: boolean) => {
            if (valid && typeof this.visibleSection.validation === 'function') {
              this.visibleSection
                .validation(this.localValue)
                .then(() => {
                  this.completeSaveAction(true)
                })
                .catch((error: Error) => {
                  WebMessage.error(error.message)
                  this.completeSaveAction(false)
                })
            } else {
              this.completeSaveAction(valid)
            }
          })
        } else {
          this.$emit(action, this.localValue)
        }
      } else {
        action(this.localValue)
      }
    }
  }

  public completeSaveAction(valid: boolean) {
    if (valid) {
      // Can Save
      this.$emit('save', this.localValue)
    } else {
      WebMessage.error('We were not able to save the record, please fix the errors before saving.')
    }
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'active_section', nextIndex)
    }
  }

  @Watch('busy')
  public onBusyChange(value: boolean) {
    this.localBusy = value
  }

  @Watch('loading')
  public onLoadingChange(value: boolean) {
    this.localLoading = value

    if (!value && this.activateAll) {
      setTimeout(() => {
        // @ts-ignore
        this.formWizard.activateAll()
      }, 1000)
    }
  }

  public created() {
    this.localBusy = this.busy
    this.localLoading = this.loading

    if (this.activateAll && this.formWizard) {
      setTimeout(() => {
        // @ts-ignore
        this.formWizard.activateAll()
      }, 1000)
    }
  }

  public mounted() {
    this.local_container = this.container
  }
}
