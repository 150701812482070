
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator'
import DataFormInput from './DataFormInput.vue'

@Component({
  components: {
    DataFormInput,
  },
})
export default class FormBuilder extends Vue {
  @Prop()
  private available_slots: any

  @Prop()
  private available_slot: any

  @Prop()
  private value: any

  @Prop()
  private fields: any

  @Prop({ default: true })
  private container!: any

  private local_container: boolean = true

  private get visibleFileds() {
    if (!this.fields) return []
    return this.fields.filter(
      (field: any) => field.visible === undefined || field.visible(this.localValue),
    )
  }

  private get localValue() {
    return this.value
  }

  private set localValue(value: any) {
    this.$emit('input', value)
  }

  @Watch('container')
  private onContainerChange(value: any) {
    this.local_container = value
  }

  public mounted() {
    this.local_container = this.container
  }
}
